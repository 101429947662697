<template>
  <div>
    <core-modal-header>Add Connections</core-modal-header>

    <core-modal-body >
      <u-tabs :items="tabs" :ui="tabConfig">
        <template #search>
          <div class="flex flex-col mt-4">
            <core-search-input
              autofocus
              class="mb-4"
              @search="search"
              @clear="clearSearch()"
              placeholder="Search By Name or Email"
              :loading="isSearching"
            />

            <div class="overflow-y-auto scrollbar-light h-80 -mr-4 pr-2">
              <transition-group name="f-slide-fade">

                <!--loading-->
                <core-contextual-loading-box
                  v-if="isLoadingPending || isSearching"
                  key="loading"
                  loading-text="Loading"
                  size="sm"
                />

                <!--search error-->
                <core-contextual-error-box
                  v-else-if="searchError"
                  key="searchError"
                  size="sm"
                  :action="search"
                />

                <!--empty state - initial load-->
                <core-empty-state
                  v-else-if="!searchTerm && !sent?.length"
                  heading="Make Some Connections!"
                  description="Invite family and friends to start making and sharing memories today."
                  size="xs"
                  icon="i-ri-user-follow-line"
                />

                <!--sent requests-->
                <div v-else-if="!searchTerm && sent?.length" key="loaded">
                  <p class="font-semibold mb-1 text-sm">Sent Connection Requests</p>
                  <ul class="list-unstyled" v-auto-animate>
                    <li v-for="item in sent" class="flex items-center p-2 gap-3 odd:bg-gray-100">
                      <user-avatar :user="item" size="lg" />
                      <span class="flex-1 font-semibold">{{item.name}}</span>
                      <user-sent-request-actions size="xs" :user="item" />
                    </li>
                  </ul>
                </div>

                <!--no results-->
                <core-empty-state
                  v-else-if="!searchResults?.length && searchTerm"
                  size="xs"
                  heading="No Results"
                  description="No users were found that match your search criteria."
                  icon="i-ri-user-search-line"
                />

                <!--search results-->
                <div v-else-if="searchResults && searchTerm">
                  <ul class="list-unstyled">
                    <li v-for="item in searchResults" class="flex items-center p-2 gap-3 odd:bg-gray-100">
                      <user-avatar :user="item" size="lg" />
                      <span class="flex-1 font-semibold">{{item.name}}</span>
                      <user-add-connection-actions :user="item" size="xs" />
                    </li>
                  </ul>

                  <p class="flex-center" v-if="searchResultsHasMore">
                    <u-button
                      class="mt-4"
                      color="charcoal"
                      size="xl"
                      variant="outline"
                      @click="search({nextPage: true})"
                      :disabled="isLoadingMore"
                      label="Load More Results"
                    />
                  </p>

                </div>

              </transition-group>
            </div>
          </div>
        </template>

        <template #invite>
          <u-form @submit="submit" :schema="schema" :state="state" :validate-on="['submit']" class="mt-4">
            <u-form-group label="Email Addresses (separated by comma)" name="to_email" class="mb-4" required>
              <u-input autofocus v-model="state.to_email" placeholder="Emails to invite" />
            </u-form-group>

            <u-form-group label="Personal Message" name="mesaage" class="mb-6">
              <u-textarea v-model="state.message" :rows="6" placeholder="Enter a Personal Message" />
            </u-form-group>

            <div class="flex gap-2 justify-end border-t border-t-gray-200 pt-3 sm:pt-4">
              <u-button variant="soft" color="charcoal" @click="modal.close()">Cancel</u-button>
              <u-button type="submit" :loading="isSaving">Send Invite</u-button>
            </div>
          </u-form>
        </template>
      </u-tabs>
    </core-modal-body>
  </div>
</template>

<script setup>
  import {object, string} from 'yup';
  import {storeToRefs} from 'pinia';

  const props = defineProps({
    modal: Object
  });

  const tabs = [
    {
      label:'Search in FOREVER',
      slot: 'search'
    },
    {
      label:'Invite By Email',
      slot: 'invite'
    }
  ];

  const tabConfig = {
    list: {
      tab: {
        active: 'font-semibold text-heroblue ',
      }
    }
  };

  //SEARCH TAB
  const usersStore = useUsersStore();
  const isSearching = ref(false);
  const isLoadingMore = ref(false);
  const searchError = ref(null);

  const searchTerm = ref(null);

  const {searchResults, searchResultsHasMore, sent} = storeToRefs(usersStore);

  const {refresh: refreshPending, pending: isLoadingPending} = await useLazyAsyncData(
    'getSent',
    usersStore.getSent
  );

  async function search({q, nextPage} = {}) {
    searchTerm.value = q || searchTerm.value;
    searchError.value = null;

    if (nextPage) {
      isLoadingMore.value = true;
    } else {
      isSearching.value = true;
    }

    try {
      await usersStore.search({term: searchTerm.value, nextPage});
    } catch (e) {
      searchError.value = e;
    } finally {
      isSearching.value = false;
      isLoadingMore.value = false;
    }
  }

  function clearSearch() {
    searchTerm.value = '';
    usersStore.clearSearchResults();
    refreshPending();
  }

  //INVITE TAB
  const isSaving = ref(false);
  const schema = object({
    to_email: string().required('At least one email is required'),
    message: string().required('Message is required.')
  });

  const state = reactive({
    to_email: '',
    message: 'Join me on FOREVER.com to see and share my photos. Plus get a sweet coupon just for signing up!'
  });

  async function submit() {
    try {
      isSaving.value = true;

      await useUserApi().inviteByEmail({
        to_email: state.to_email.split(/[,;\s]+/).map(e => e.trim()).filter(e => e),
        message: state.message
      });
      useSuccessToast().add({
        id: 'connection-add',
        title: `Invite Sent`,
        description: `Your invite has been sent.`
      });
      props.modal.close();
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }

  onUnmounted(() => {
    usersStore.clearSearchResults();
    usersStore.clearSent();
  });
</script>
