<template>
  <u-button
    @click="cancelSentRequest"
    :loading="isSaving"
    :icon="COMMON_ICONS.close"
    :size="size"
    variant="outline"
    color="charcoal"
  >
    Cancel Sent Request
  </u-button>
</template>

<script setup>
  const props = defineProps({
    user: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'sm'
    }
  });

  const isSaving = ref(false);
  async function cancelSentRequest() {
    try {
      isSaving.value = true;
      await useUsersStore().cancelSentRequest({user: props.user});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
