<template>
  <div class="flex gap-2">
    <core-responsive-button @click="acceptPending" :size="size" icon="i-ri-check-line" :loading="isSaving">Accept</core-responsive-button>
    <core-responsive-button @click="ignorePending" :size="size" :icon="COMMON_ICONS.close" color="charcoal" variant="outline">Ignore</core-responsive-button>
  </div>
</template>

<script setup>
  const props = defineProps({
    user: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'sm'
    }
  });

  const isSaving = ref(false);
  async function acceptPending() {
    try {
      isSaving.value = true;
      await useUsersStore().acceptPendingRequest({user: props.user})
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }

  async function ignorePending() {
    try {
      useUsersStore().ignorePendingRequest({user: props.user})
    } catch (e) {
      useErrorToast().add();
    }
  }
</script>
