<template>
  <div class="relative p-3 sm:p-6 lg:p-10">
    <div class="flex flex-col gap-2">
      <div class="w-36 sm:w-52 h-36 sm:h-52 rounded-full flex items-center justify-center mx-auto">
        <user-avatar
          :user="user"
          size="md"
          :ui="avatarConfig"
        />
      </div>
      <h3 class="font-serif text-center text-3xl md:text-5xl py-1">{{user.name}}</h3>
    </div>

    <div class="bg-white/75 px-5 pt-5" v-if="user.bio">
      <core-fancy-text-display :text="user.bio" />
    </div>

    <!--if this is current user (auth token is as good as any indicator)-->
    <div class="text-center" v-else-if="user.auth_token">
      <u-divider class="my-5" />
      <p class="text-charcoal-400 text-lg mb-5">
        <template v-if="user.isOrganization">You have not yet added a description for your organization.</template>
        <template v-else>You have not yet added a bio.</template>
      </p>
      <u-button @click="editProfile({user})" class="min-w-44 justify-center">Edit Profile</u-button>
    </div>

    <div v-else class="text-center">
      <u-divider class="my-5" />
      <p class="text-charcoal-400 text-lg">This user has not yet added a bio.</p>
    </div>
  </div>
</template>

<script setup>

  const {editProfile} = useUserActions();

  const props = defineProps({
    user: Object,
    modal: Object
  });

  const avatarConfig = {
    wrapper: 'border-2 border-white shadow-xl !w-full !h-auto aspect-[1/1]',
    size: {md: '!w-full !h-auto aspect-[1/1] text-5xl'}
  };
</script>
