<template>
  <span v-if="isCurrentUser">It's you!</span>

  <!--already connected-->
  <span v-else-if="isConnection">
    <user-friend-badge />
  </span>

  <!--pending connection-->
  <user-pending-actions
    v-else-if="isRequestReceived"
    :size="size"
    :user="user"
  />

  <!--sent request-->
  <user-sent-request-actions
    v-else-if="isRequestSent"
    :size="size"
    :user="user"
  />

  <u-button
    v-else
    @click="addConnection"
    :size="size"
    :icon="COMMON_ICONS.add"
    :loading="isSaving"
    label="Add Connection"
  />
</template>

<script setup>
  const props = defineProps({
    user: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: 'sm'
    }
  });

  const isCurrentUser = computed(() => useSessionStore().currentUserId === props.user.id);
  const isConnection = computed(() => props.user.friendship.status === FRIEND_STATUS.completed);
  const isRequestSent = computed(() => props.user.friendship.status === FRIEND_STATUS.sent);
  const isRequestReceived = computed(() => props.user.friendship.status === FRIEND_STATUS.pending);

  const isSaving = ref(false);
  async function addConnection() {
    try {
      isSaving.value = true;
      await useUsersStore().addConnection({user: props.user});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSaving.value = false;
    }
  }
</script>
