<template>
  <div>
    <core-modal-header>Manage Accounts</core-modal-header>
    <core-modal-body>
      <div class="grid gap-2">
        <div
          v-for="connection in managedConnections"
          :key="connection.id"
          @click="() => navigateTo(`/impersonate/${connection.id}`)"
          role="button"
          class="p-2.5 border group hover:bg-shell-200 border-charcoal-200 bg-white transition-all relative rounded-md flex gap-2 items-center"
        >
          <user-avatar :user="connection" size="xl" />
          <h3 class="font-semibold text-base break-long-string flex-1">{{connection.name}}</h3>
          <span class="group-hover:block hidden font-semibold text-xs text-charcoal-400">MANAGE ACCOUNT</span>
        </div>
      </div>
    </core-modal-body>
  </div>
</template>

<script setup>
  const usersStore = useUsersStore();
  const {managedConnections} = storeToRefs(usersStore);
</script>
